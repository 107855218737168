import { render } from "./GoQuestionnaireView.vue?vue&type=template&id=730e7c96"
import script from "./GoQuestionnaireView.vue?vue&type=script&lang=ts"
export * from "./GoQuestionnaireView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "730e7c96"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('730e7c96', script)) {
    api.reload('730e7c96', script)
  }
  
  module.hot.accept("./GoQuestionnaireView.vue?vue&type=template&id=730e7c96", () => {
    api.rerender('730e7c96', render)
  })

}

script.__file = "src/views/GoQuestionnaireView.vue"

export default script